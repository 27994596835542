import React, { useEffect, useContext, useState } from "react";
import MissionSVG from "../../../svg/MissionStatementSection/tccmission.svg";
import WindowSizeContext from "../../contexts/windowSizeContext";

/**
 * This component renders the mission statement block used in the Landing Page.
 *
 * @returns {element} A section element containing the layout, text and images for the mission statement.
 */
export default () => {
  const windowSize = useContext(WindowSizeContext);

  const [isLargeScreenView, setIsLargeScreenView] = useState(windowSize.isLargeScreenView);

  useEffect(() => {
    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [windowSize.isLargeScreenView]);

  return (
    <section>
      <div className="container mx-auto">
        <div className="flex flex-wrap pt-1 overflow-hidden lg:pt-10">
          <div className="w-full overflow-hidden xl:w-2/12"></div>

          <div className="flex flex-wrap w-full overflow-hidden text-center lg:text-left xl:w-8/12">
            <div className="flex flex-col pt-10 pb-10 pr-4 lg:w-7/12">
              <h1 className="text-2xl">The Comparison Company Mission</h1>

              <p className="pt-8">The world is weird and difficult enough at the moment without worrying about utility bills.</p>
              <p>Nobody enjoys shopping for utilities, so we built a way to simplify the process. The goal is to save you time and money while protecting your privacy. It’s as simple as that.</p>
              <p>One Site, All Utilities.</p>
              <p>Other Sites Just Don’t Compare</p>
            </div>

            {isLargeScreenView && (
              <div className="flex flex-col w-5/12 pt-10 text-right ">
                <MissionSVG className="ml-auto align-top" />
              </div>
            )}
          </div>

          <div className="w-full overflow-hidden xl:w-2/12"></div>
        </div>
      </div>
    </section>
  );
};
